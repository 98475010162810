import PanoramaIcon from '@mui/icons-material/Panorama';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import LandscapeIcon from '@mui/icons-material/Landscape';
import KayakingIcon from '@mui/icons-material/Kayaking';
import HikingIcon from '@mui/icons-material/Hiking';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { Landscape } from '@mui/icons-material';

/* Modificar whatsapp */

export const DatosTours = [
    
    {
        id: 'pasarelas',
        imagen: 'pasarelas',
        icono: <PanoramaIcon/>,
        tour: 'Pasarelas Glaciar Perito Moreno',
        duracion: ' libre',
        salidas: ' Consultar ',
        link: 'https://walink.co/28c1b8',
        incluye: 'Translado - Guia',
        spanishDesc: 'Esta excursión es la más importante y concurrida de la zona. El glaciar es un espectáculo indescriptible que colma al visitante de asombro y admiración, incluso ya desde el primer avistamiento a varios kilómetros de distancia en el camino. El recorrido desde El Calafate hasta el glaciar es una sucesión de lagos, arroyos, bosques y montañas nevadas. La contemplación de este fenómeno natural deja sin aliento a miles de turistas que llegan desde diversas partes del mundo.',
        englishDesc: "This excursion is the most important and popular in the area. The glacier is an indescribable spectacle that fills the visitor with amazement and admiration, even from the first sighting several kilometers away along the way. The route from El Calafate to the glacier is a succession of lakes, streams, forests and snow-capped mountains. Contemplation of this natural phenomenon leaves thousands of tourists who come from different parts of the world breathless.",
        src1: "pasarelas1",
        src2: "pasarelas2",
        src3: "pasarelas3",
    },  
    {
        id: 'moreno-spirit',
        imagen: 'morenoSpirit',
        icono: <DirectionsBoatIcon/>,
        tour: 'Moreno Spirit',
        duracion: ' 1 Hora',
        salidas: ' Consultar ',
        link: 'https://walink.co/fd1f52',
        incluye: ' Translado - Guia - Servicio de fotografia',
        spanishDesc: 'Salimos todos los días desde Puerto Moreno (ubicado al pie de las pasarelas del paseo costero) sobre el Canal de los Témpanos. En segundos nuestra embarcación arriba al frente de la pared norte del glaciar, donde se puede apreciar su altura y su tamaño, desde una perspectiva totalmente diferente a lo que se observa desde las pasarelas. Visitamos la zona donde (cada cierta cantidad de años) se produce la famosa ruptura del glaciar, fenómeno que ha hecho que el glaciar Perito Moreno sea conocido a nivel mundial. Luego realizamos una lenta navegación frente a todo el ancho de la pared norte. Si tenemos suerte, podremos observar algún desprendimiento del glaciar.',
        englishDesc: "We leave every day from Puerto Moreno (located at the foot of the coastal walkway) on the Canal de los Témpanos. In seconds our boat reaches the front of the north wall of the glacier, where you can appreciate its height and size, from a totally different perspective than what is seen from the walkways. We visited the area where (every certain number of years) the famous glacier break occurs, a phenomenon that has made the Perito Moreno glacier known worldwide. Then we made a slow navigation in front of the entire width of the north wall. If we are lucky, we will be able to observe some glacier calving.",
        src1: "morenoSpirit2",
        src2: "morenoSpirit1",
        src3: "morenoSpirit3",
    }, 
    {
        id: 'todoGlaciares',
        imagen: 'todoGlaciares',
        icono: <DirectionsBoatIcon/>,
        tour: 'Todo Glaciares',
        duracion: ' 7 Horas',
        salidas: ' Consultar ',
        link: 'https://walink.co/6082be',
        incluye: 'Navegacion',
        spanishDesc: 'La aventura da comienzo en el Puerto de Punta Bandera, a 47 km de El Calafate, en donde se inicia la navegación por el Brazo Norte del Lago Argentino. Atravesando  la Boca del Diablo con destino al Canal Upsala y para navegar entre los grandes témpanos que se desprenden del frente del Glaciar Upsala. Luego ingresan al Canal Spegazzini y momentos después tendrán la primera vista del Glaciar Seco. Continúan  navegando hasta el frente del Glaciar Spegazzini donde también contemplaran los glaciares Heim Sur y Peineta. Nos preparamos para desembarcar en La Base Spegazzini y comenzamos la visita al área caminando a través del sendero del bosque hasta el Refugio Spegazzini. Este sendero de 300 metros cuenta con vistas inigualables de La Bahía de Los Glaciares, en su recorrido podremos disfrutar de estaciones temáticas y miradores.',
        englishDesc: "The adventure starts in the Port of Punta Bandera, 47 km from El Calafate, where navigation along the North Arm of Lake Argentino begins. Crossing the Boca del Diablo towards the Upsala Canal and to navigate between the large icebergs that break off from the front of the Upsala Glacier. Then they enter the Spegazzini Channel and moments later they will have the first view of the Seco Glacier. They continue sailing to the front of the Spegazzini Glacier where they will also contemplate the Heim Sur and Peineta glaciers. We prepare to disembark at La Base Spegazzini and start the visit to the area by walking through the forest path to the Spegazzini Shelter. This 300-meter trail has unparalleled views of La Bahía de Los Glaciares, along its route we can enjoy themed stations and viewpoints.",
        src1: "todoGlaciares1",
        src2: "todoGlaciares2",
        src3: "todoGlaciares3",
    },
    {
        id: 'mayoSpirit',
        imagen: 'mayoSpirit',
        icono: <DirectionsBoatIcon/>,
        tour: 'Mayo Spirit Trek',
        duracion: ' 8 horas',
        salidas: ' consultar ',
        link: 'https://walink.co/f7b36b',
        incluye: 'Translado - Guia - servicio de fotografia',
        spanishDesc: 'Salimos desde el puerto Punta Bandera. Comenzamos a navegar por el Canal de los Témpanos y luego ingresamos al Seno Mayo del Lago Argentino. Desembarcamos en Bahía Toro acompañados por nuestro guía, donde realizamos una caminata interpretativa para observar el bosque andino patagónico. Regresamos a la embarcación y desembarcamos en Cerro Negro. Iniciamos una caminata de unos 1.000 mts, internándonos en el bosque andino patagónico, siguiendo la traza de un arroyo que nos lleva hasta la base del Cerro Negro, desde donde podemos contemplar el maravilloso Glaciar Negro. Luego nos trasladamos hasta el glaciar más famoso de la Patagonia, el Perito Moreno. Único por su belleza e imponencia, ésta enorme masa de hielo nace en el Campo de Hielo Patagónico Sur y recorre 30km de largo hasta llegar y finalizar frente a la Península de Magallanes, sobre el Lago Argentino, donde sus paredes sobrepasan los 60 mts de altura. Realizamos una navegación imperdible que recorre su frente norte y área de la ruptura. Por último, desembarcamos en el Puerto Moreno y recorremos las pasarelas. Regresamos al barco y emprendemos la vuelta al puerto de Punta Bandera.',
        englishDesc: "We leave from the Punta Bandera port. We start to navigate the Canal de los Témpanos and then enter the Seno Mayo of Lake Argentino. We disembark in Bahía Toro accompanied by our guide, where we take an interpretive walk to observe the Andean Patagonian forest. We return to the boat and disembark in Cerro Negro. We begin a walk of about 1000 meters, entering the Andean Patagonian forest, following the trace of a stream that takes us to the base of Cerro Negro, from where we can contemplate the wonderful Black Glacier. Then we moved to the most famous glacier in Patagonia, the Perito Moreno. Unique for its beauty and grandeur, this enormous mass of ice is born in the Southern Patagonian Ice Field and runs 30km long until it reaches and ends in front of the Magellan Peninsula, on Lake Argentino, where its walls exceed 60 meters in height. . We carry out an unmissable navigation that runs along its northern front and area of ​​the rupture. Finally, we disembark in Puerto Moreno and walk the walkways. We return to the boat and start the return to the port of Punta Bandera.",
        src1: "mayoSpirit1",
        src2: "mayoSpirit2",
        src3: "mayoSpirit3",
    },    
    {
        id: 'minitrek',
        imagen:'minitrek',
        icono: <DirectionsBoatIcon/>,
        tour: 'Minitrekking Perito Moreno',
        duracion: 'Dia completo',
        salidas: 'Consultar',
        link: 'https://walink.co/9ff661',
        incluye: 'traslado - Guia - Equipamiento',
        spanishDesc: 'Una actividad que te permite caminar sobre el Glaciar y explorar su impresionante paisaje de hielo. La excursión comienza en el puerto Bajo las Sombras, donde embarcas para cruzar a la costa opuesta. Cuando desembarcas, los guías te reciben para caminar hasta los pies del glaciar. Antes de comenzar el trekking, te proporcionan crampones y casco, y te instruyen respecto a las medidas de seguridad.  Durante el minitrekking, caminarás aproximadamente una hora y media sobre el glaciar Perito Moreno. Podrás explorar grietas, lagunas y formaciones de hielo. Finalizando la caminata y aún sobre el Glaciar, vas a disfrutar de una bebida y un chocolate. Luego, comienza el regreso al punto de partida, donde entregas los crampones y el casco, y tomás un sendero por el bosque para acercarte al refugio. Si el tiempo lo permite, podés disfrutar de tu almuerzo en el refugio, con una vista privilegiada al Glaciar y al Brazo Rico del Lago Argentino. Finalizando la excursión, embarcas para regresar a Puerto Bajo Las Sombras. Antes o despues del Minitrekking tendrás 45 minutos para recorrer las pasarelas.',
        englishDesc: 'An activity that allows you to walk on the Glacier and explore the impressive ice landscape. The excursion starts at the Bajo las Sombras port, where you board to cross to the opposite coast. When you disembark, the guides welcome you to walk to the foot of the glacier. Before starting the trek, they provide you with crampons and a helmet, and instruct you regarding safety measures.  During the minitrekking, you will walk for approximately an hour and a half on the Perito Moreno glacier. You will be able to explore crevices, lagoons and ice formations. At the end of the walk and still on the Glacier, you will enjoy a drink and a chocolate. Then, the return to the starting point begins, where you hand over your crampons and helmet, and take a path through the forest to get closer to the shelter. If time permits, you can enjoy your lunch at the refuge, with a privileged view of the Glacier and the Brazo Rico of Lake Argentino. At the end of the excursion, you board to return to Puerto Bajo Las Sombras. Before or after the Minitrekking you will have 45 minutes to walk the walkways in front of Perito Moreno glacier.',
        src1: "minitrek1",
        src2: "minitrek2",
        src3: "minitrek3",
    },
    {
        id: 'safariAzul',
        imagen:'safariAzul',
        icono: <DirectionsBoatIcon/>,
        tour: 'Safari Azul',
        duracion: 'Medio Dia',
        salidas: 'Consultar',
        link: 'https://walink.co/02443e',
        incluye: 'Traslado  - Navegacion',
        spanishDesc: 'Combina las sensaciones de navegar frente al Glaciar Perito Moreno y de poder tocarlo con tus propias manos. Esta excursión comienza en Puerto Bajo las Sombras, con una navegación corta frente al Glaciar, para apreciar la inmensidad de sus paredes desde el agua. La aventura comienza cuando desembarcamos en la costa opuesta. Emprendemos una caminata a orilla del Brazo Rico, apreciando el paisaje que lo rodea y los icebergs que asoman a la superficie. Luego de una hora de caminata, llegas al pie del Glaciar Perito Moreno, pudiendo observar sus diferentes tonalidades, desde el blanco hasta el azul intenso. Puedes tocarlo con tus manos y probar el hielo glaciario. Si las condiciones del Glaciar lo permiten, puedes ubicarte en una de sus cuevas, donde el color se vuelve intenso. Una vez terminada la actividad, regresamos al punto donde desembarcamos por un sendero en el bosque y regresamos a la costa.',
        englishDesc: 'Combine the sensations of sailing in front of the Perito Moreno Glacier and being able to touch it with your own hands. This excursion begins in Puerto Bajo las Sombras, with a short navigation in front of the Glacier, to appreciate the immensity of its walls from the water. The adventure starts when we disembark on the opposite coast. We start a walk along the shore of the Brazo Rico, appreciating the landscape that surrounds it and the icebergs that appear on the surface. After an hour of walking, you arrive at the foot of the Perito Moreno Glacier, being able to observe its different shades, from white to intense blue. You can touch it with your hands and taste the glacial ice. If the conditions of the Glacier allow it, you can go to one of its caves, where the color becomes intense. Once the activity is over, we return to the point where we disembark along a path in the forest and return to the coast.',
        src1: "safariAzul1",
        src2: "safariAzul2",
        src3: "safariAzul3",
    },
    {
        id: 'punWa',
        imagen: 'punWa',
        icono: <AirportShuttleIcon/>,
        tour: 'Nativo Experience Punta Walichu',
        duracion: ' 3 Horas',
        incluye: 'Translado - Travesia 4x4 - Guia - Entrada - Almuerzo o Cena',
        salidas: '  Consulatr ',
        link: 'https://walink.co/f8ede3',
        spanishDesc: 'Excursión de mediodía en 4x4 por la costa del Lago Argentino. Ideal para el día de llegada o de partida, tiene un enfoque antropológico, haciendo un recorrido en el tiempo sobre el paso del hombre en estas tierras, re-descubriendo historias de las primeras expediciones y osados que se aventuraron a lo desconocido. A orillas del Lago Argentino nuestros guías dictan una charla acerca de la formación del paisaje del lugar, sobre la fauna y la flora autóctona. Realizamos un ascenso para obtener una increíble imagen panorámica de la ciudad y sus alrededores. Desde ahí apreciamos la extensión del Lago Argentino y la Cordillera de Los Andes. Recorremos a pie (500m) el sitio arqueológico mientras interpretamos el significado de las pinturas rupestres que vemos en las rocas y las técnicas utilizadas para realizarlas. Finalizamos el recorrido disfrutando de una deliciosa comida patagónica en una locación inolvidable.',
        englishDesc: "Midday 4x4 excursion along the coast of Lake Argentino. Ideal for the day of arrival or departure, it has an anthropological approach, taking a journey through time about the passage of man in these lands, rediscovering stories of the first expeditions and daring people who ventured into the unknown. On the shores of Lake Argentino our guides give a talk about the formation of the landscape of the place, about the fauna and the native flora. We ascend to obtain an incredible panoramic image of the city and its surroundings. From there we appreciate the extension of Lake Argentino and the Andes Mountains. We tour the archaeological site on foot (500m) while we interpret the meaning of the cave paintings that we see on the rocks and the techniques used to make them. We finish the tour enjoying a delicious Patagonian meal in an unforgettable location.",
        src1: "punWa1",
        src2: "punWa2",
        src3: "punWa3",
    },
    {
        id: 'cerroFrias',
        imagen: 'cerroFrias',
        icono: <LandscapeIcon/>,
        tour: 'Cerro Frias 4x4/Cabalgata',
        duracion: ' 4 Horas',
        salidas: ' Consultar ',
        link: 'https://walink.co/b4ba2d',
        incluye: 'Translado - Actividad a eleccion- Almuerzo/Merienda/Cena (Depende el horario)',
        spanishDesc: 'Cerro Frías 4x4 y Cabalgata. A 25km de El Calafate, se puede optar por ascender en 4x4 al Cerro Frías hasta dos puntos panorámicos a 450 y 1.030mts snm incluyendo el balcón del Parque Nacional, o realizar una cabalgata a través de vegas y bosques, subiendo los faldeos del Cerro Frías. En ambas excursiones podremos apreciar vistas del Lago Argentino, Torres del Paine, Boca del Diablo y los picos más altos de la Cordillera de los Andes y Cerro Fitz Roy, encontrando en el camino animales silvestres y autóctonos de la zona. Al regresar descendemos por nuestro bosque nativo de lengas en la ladera SudEste del Cerro Frías. "ALMUERZO": GUISO PATAGONICO + POSTRE. MENU VEGETARIANO DISPONIBLE(SOLICITAR CON ANTELACION). BEBIDAS NO INCLUIDAS. "MERIENDA": CAFÉ, TÉ O CHOCOLATADA & TARTAS DULCES. "CENA": SOPA DE VEGETALES + CAZUELA DE CORDERO + POSTRE',
        englishDesc: 'Cerro Frías 4x4 and Horseback Riding. 25km from El Calafate, you can choose to ascend Cerro Frías in a 4x4 to two panoramic points at 450 and 1,030 meters above sea level, including the balcony of the National Park, or take a horseback ride through meadows and forests, climbing the slopes of Cerro Frías. . On both excursions we will be able to appreciate views of Lago Argentino, Torres del Paine, Boca del Diablo and the highest peaks of the Andes Mountains and Cerro Fitz Roy, encountering wild and native animals of the area along the way. Upon returning we descend through our native lenga forest on the southeastern slope of Cerro Frías. "LUNCH": PATAGONIAN STEW + DESSERT. VEGETARIAN MENU AVAILABLE (REQUEST IN ADVANCE). DRINKS NOT INCLUDED. "SNACK": COFFEE, TEA OR CHOCOLATE & SWEET CAKES. "DINNER": VEGETABLE SOUP + LAMB CASSEROLE + DESSERT',
        src1: "cerroFrias1",
        src2: "cerroFrias2",
        src3: "cerroFrias3",
    },   
    {
        id: '25demayo',
        imagen:'25demayo',
        icono: <DirectionsBoatIcon/>,
        tour: 'Estancia 25 de Mayo',
        duracion: 'A eleccion',
        salidas: 'A partir de Septiembre',
        link: 'https://walink.co/8e1e5f',
        incluye: 'traslado - A eleccion Merienda - Cena - Cabalgata',
        spanishDesc: 'La estancia 25 de mayo cuenta con 17000 hectáreas de campo, y su antigua casco se encuentra ubicado a pasos del centro de El Calafate, un lugar ideal dende descansar y disfrutar del paisaje al pie del cerro calafate. Contamos con tres opciones: 1) "Tarde de Campo" donde podrás disfrutar de un cálido fogón con tortas fritas y café carretero, podrás presenciar arreo de ovejas y trabajo de corrales, demostración de esquila y caminata por el arroyo. A esta actividad podrás adicionar la opción de cabalgata (No es necesario conocimiento previo). 2) "Tarde de Campo y Cena" Sumado a las actividades anteriores podrás disfrutar de  una cena en "El Quincho" donde podrás degustar cordero confitado, vino montañés, y la cena que incluye pan de campo, empanada criolla, cordero al asador, pollo, chorizo, papas rusticas y ensaladas. 3) "Cena Show" Esta opción incluye solo la cena mencionada anteriormente.',
        englishDesc: 'Estancia 25 de Mayo has 17,000 hectares of countryside, and its old town is located steps from the center of El Calafate, an ideal place to rest and enjoy the landscape at the foot of Cerro Calafate. We have three options: 1) "Tarde de campo" where you can enjoy a warm bonfire with Tortas fritas  and road coffee, you can witness sheep herding and corral work, shearing demonstration and walk along the stream. To this activity you can add the horseback riding option (No prior knowledge is necessary). 2) "Tarde de Campo and Dinner" In addition to the previous activities, you can enjoy a dinner at "El Quincho" where you can taste confit lamb, mountain wine, and dinner that includes country bread, Creole empanada, grilled lamb, chicken, chorizo, rustic potatoes and salads. 3) "Dinner Show" This option includes only the dinner mentioned above.',
        src1: "25demayo1",
        src2: "25demayo2",
        src3: "25demayo3",
    },        
    {
        id: 'chalten',
        imagen: 'chalten',
        icono: <HikingIcon/>,
        tour: 'Chalten Full Day',
        duracion: ' 10 horas',
        salidas: ' Consultar ',
        link: 'https://walink.co/843bb3',
        incluye: 'Translado - Guia - Almuerzo',
        spanishDesc: 'Sabias que el Chalten es La Capital Nacional de El Trekking? Pasamos a buscarte por el alojamiento a las 8 de la mañana y emprendemos el viaje hacia el chalten. El viaje dura 4 horas y paramos en los miradores para apreciar los maravillosos paisajes. Llegados al chalten Realizaremos el sendero "Mirador de los condores", Luego regresamos al pueblo para disfrutar de un almuerzo en restaurante (no incluye bebidas). Luego de almorzar realizaremos el sendero "Chorrillo del Salto". 16hs emprendemos el regreso a la ciudad del Calafate llegando aproximadamente a las 18hs',
        englishDesc: 'Did you know that Chalten is the National Capital of Trekking? We will pick you up at your accommodation at 8 in the morning and start the trip to El Chalten. The trip lasts 4 hours and we stop at the viewpoints to appreciate the wonderful landscapes. Arrived at Chalten, we will take the "Mirador de los Condores" trail. Then we will return to the town to enjoy lunch at a restaurant (drinks not included). After lunch we will take the "Chorrillo del Salto" trail. At 4:00 p.m. we start the return to the city of Calafate, arriving at approximately 6:00 p.m." ',
        src1: "chalten1",
        src2: "chalten2",
        src3: "chalten3",
    },  
    {
        id: 'lagunaDeLosTres',
        imagen:'lagunaDeLosTres',
        icono: <HikingIcon/>,
        tour: 'Laguna De Los Tres (Chalten)',
        duracion: '15 horas',
        salidas: 'A partir de Septiembre',
        link: 'https://walink.co/91b1a0',
        incluye: 'Traslado - Equipamiento',
        spanishDesc: 'Se trata de una caminata desafiante por la senda del Fitz Roy, de 20 kilómetros totales. Durante el primer tramo vas atravesar el bosque hasta llegar a Laguna Capri. Te va a sorprender elcolor del agua y la naturaleza que rodea esta laguna, además, las vistas al Fitz Roy son imperdibles. Todo el esfuerzo vale la pena. Al llega a Laguna de los Tres, te sorprende la inmensa naturaleza y el color del agua. La sensación es imposible de describir en palabras. **Esta actividad no incluye guia.',
        englishDesc: 'It is a challenging walk along the Fitz Roy path, 20 total kilometers. During the first section you will go through the forest until you reach Laguna Capri. You will be surprised by the color of the water and the nature that surrounds this lagoon, in addition, the views of Fitz Roy are unmissable. All the effort is worth it. When you arrive at Laguna de los Tres, you are surprised by the immense nature and the color of the water. The feeling is impossible to describe in words. **This activity does not include a guide.',
        src1: "lagunaDeLosTres1",
        src2: "lagunaDeLosTres3",
        src3: "lagunaDeLosTres4",
    },
    {
        id: 'lagunaTorre',
        imagen:'lagunaTorre',
        icono: <HikingIcon/>,
        tour: 'Laguna Torre (Chalten)',
        duracion: '15 horas',
        salidas: 'A partir de Septiembre',
        link: 'https://walink.co/cf916e',
        incluye: 'Traslado - Equipamiento',
        spanishDesc: 'El sendero a Laguna Torre te lleva hasta los pies de este espejo de agua, formado por el des hielo glaciario. De fondo, el Cerro Torre, conocido por ser uno de los más difíciles de escalar para los alpinistas profesionales. Se trata de una caminata de 18 kilómetros totales. Pasarás por diferentes miradores, atravensando valle y bosque. Desde la laguna, es común ver témpanos flotando, los que se desprenden del imponente glaciar que se esconde entre el Cerro Torre. Esta actividad no incluye guia.',
        englishDesc: 'The trail to Laguna Torre takes you to the foot of this reflecting pool, formed by glacial ice. In the background, Cerro Torre, known for being one of the most difficult to climb for professional mountaineers. It is a walk of 18 total kilometers. You will pass through different viewpoints, crossing valley and forest. From the lagoon, it is common to see icebergs floating, which break off from the imposing glacier that hides between Cerro Torre. This activity does not include a guide.',
        src1: "lagunaTorre1",
        src2: "lagunaTorre2",
        src3: "lagunaTorre3",
    },
    {
        id: 'torresDelPaine',
        imagen:'torresDelPaine2',
        icono: <HikingIcon/>,
        tour: 'Torres Del Paine (Chile)',
        duracion: '16 horas',
        salidas: 'Consultar',
        link: 'https://walink.co/9ad1db',
        incluye: 'traslado - guia - boxLunch',
        spanishDesc: 'Descubrí una de las octavas maravillas del mundo, en elcorazón de la Patagonia Austral. Esta aventura te lleva a recorrer uno de los parques más lindos, formado por montañas, glaciares, lagos y cascadas. Durante el recorrido, podes avistar zorros, guanacos, y quizás pumas. El vehículo te acerca a puntos estratégicos, desde donde emprendes cortas caminatas para acceder a los miradores más importantes del Parque. Consultar requisitos para ingresar a Chile.',
        englishDesc: 'I discovered one of the eighth wonders of the world, in the heart of Southern Patagonia. This adventure takes you to explore one of the most beautiful parks, made up of mountains, glaciers, lakes and waterfalls. During the tour, you may spot foxes, guanacos, and perhaps pumas. The vehicle takes you to strategic points, from where you undertake short walks to access the most important viewpoints of the Park. Check requirements to enter Chile.',
        src1: "torresDelPaine1",
        src2: "torresDelPaine3",
        src3: "torresDelPaine",
    },
    {

        id: "kayak",
        imagen: 'kayak',
        icono: <KayakingIcon/>,
        tour: "Darwin Kayak",
        duracion: " 5:30 Horas",
        incluye: "Translado, Guia, Vianda",
        salidas: " consultar ",
        link: 'https://walink.co/24f2e4',
        spanishDesc: "La actividad comienza cuando los buscan en vehículo 4x4 por sus hoteles y luego ya se dirigen al Paraje Charles Fuhr ubicado a 40 km de la ciudad.  Les facilitarán todo el equipamiento necesario que está incluido (trajes secos de gore tex, chaleco salvavidas, botas y guantes). Una vez preparados comienza el kayak por el Río Santa Ceuz que durará 80 min recorriendo 15 km hasta llegar a la Estancia El Rincón. Al llegar a la estancia, se les ofrecerá el almuerzo. Entrada, plato principal, bebida y postre.  Estará siempre acompañado por un guía y se reunirá en la estancia con el resto del grupo para almorzar.",
        englishDesc: "The activity start when you are picked up by 4x4 vehicle at your hotel and then you head to the Charles Fuhr Park located 40 km from the city. We will provide you with all the necessary equipment that is included (gore tex dry suits, life jacket, boots and gloves). Once prepared, the kayak start along the Santa Ceuz River, which will last 80 minutes, traveling 15 km until reaching Estancia El Rincón. Upon arrival at the ranch, you will be offered lunch. Starter, main course, drink and dessert. You will always be accompanied by a guide and will meet at the ranch with the rest of the group for lunch.",
        src1: "kayak1",
        src2: "kayak2",
        src3: "kayak3",
    },  
    {
        id: 'calafateNight',
        imagen: 'calafateNight',
        icono: <AutoGraphIcon fontSize='large'/>,
        tour: 'Calafate Night',
        duracion: ' 5 horas',
        salidas: ' consultar ',
        link: 'https://walink.co/72c8f1',
        incluye: 'Translado - Guia - Binoculares - Telescopio - Poncho(Abrigo) - Cena',
        spanishDesc: 'Redescubrí el cielo como nunca antes!! Una experiencia para las mentes curiosas. Acompañado de telescopios y un astrónomo experto con mas de 10 años de experiencia, aprenderás todo sobre la astronomía, podras observar miles de estrellas, la vía láctea, satélites, mitología y mucho más. ITINERARIO = 19.00hs Búsqueda en los hoteles. 20.00hs Arribo a Estancia Tierras donde podrás visitar los alrededores de la estancia y conocerás la historia del lugar, sus costumbres y mucho más. 20.30hs Cena con vista panorámica de los cerros que rodean la estancia.    Cena con modalidad "Buffet Libre de Pastas" Incluye pastas largas, lasagna a la bolognesa, sorrentinos o ravioles, ñoquis. (Opcion vegetariana/vegana)     Acompañado de las mejores salsas como 4 quesos, salsa blanca, bolognesa, fileto. Tambien incluye postre, una bebida de vino cada 2 personas o una bebida gaseosa o agua mineral por persona. 22.00hs Observación del cielo y estrellas. Cerca de las 22 hs habrá una proyección fílmica de 15 minutos para introducir a las personas a la astronomía. Luego se verá el cielo, y el astrónomo les guiará como mirar el cielo, utilizar el telescopio, aprender sobre la vía láctea, nebulosas, estrellas, constelaciones, mitología, y más. También se hará fotografía nocturna y Light Painting. 00.00hs Regreso a hoteles',
        englishDesc: 'Rediscover the sky like never before!! An experience for curious minds. Accompanied by telescopes and an expert astronomer with more than 10 years of experience, you will learn everything about astronomy, you will be able to observe thousands of stars, the Milky Way, satellites, mythology and much more. ITINERARY = 7:00 p.m. Search at the hotels. 8:00 p.m. Arrival at Estancia Tierras where you can visit the surroundings of the ranch and learn about the history of the place, its customs and much more. 8:30 p.m. Dinner with a panoramic view of the hills that surround the ranch. Dinner with "All-Paste Buffet" modality Includes long pasta, lasagna bolognese, sorrentinos or ravioli, gnocchi. (Vegetarian/vegan option) Accompanied by the best sauces such as 4 cheeses, white sauce, bolognese, filet. It also includes dessert, a drink of wine for every 2 people or a soft drink or mineral water per person. 10:00 p.m. Observation of the sky and stars. Around 10 p.m. there will be a 15-minute film screening to introduce people to astronomy. Then you will see the sky, and the astronomer will guide you how to look at the sky, use the telescope, learn about the Milky Way, nebulae, stars, constellations, mythology, and more. Night photography and Light Painting will also be done. 00.00hs Return to hotels',
        src1: "calafateNight1",
        src2: "calafateNight2",
        src3: "calafateNight3",
    },      
    {
        id: 'cityWalichu',
        imagen: 'cityWalichu',
        icono: <Landscape/>,
        tour: 'City Tour & Punta Walichu',
        duracion: ' 3 horas',
        salidas: ' Consultar ',
        incluye: 'Translado - Guia',
        link: 'https://walink.co/4343ed',
        spanishDesc: 'Comenzamos con la visita a Punta Walichu, sitio arqueológico ubicado a 8 Km. de la localidad, que te mostrará las huellas de los primeros hombres que caminaron estas tierras. Una caminata a lo largo de los aleros y las cuevas te permitirá observar pinturas rupestres (originales y reproducciones) así como una excepcional vista del Lago Argentino y la Cordillera de los Andes. El circuito transita también las principales calles del casco antiguo del pueblo, mostrándote las típicas primeras construcciones de los pioneros así como los edificios del centro cívico actuales. El guía será el responsable de transportarte en el tiempo hacia la historia de los primeros pobladores, te mostrara la flora, la fauna y otros interesantes detalles que atesora esta pequeña Villa Turística. Realizarás una visita por el Paseo Costero y la Bahía Redonda, a metros de la costa del Lago Argentino, en donde podrás observar la abundante avifauna local. Como última escala, en el Centro de Interpretación Histórica observarás a través de una visita auto-guiada, los hechos de mayor relevancia histórica desde las glaciaciones al presente.',
        englishDesc: "We start with a visit to Punta Walichu, an archaeological site located 8 km from the town, which will show you the footprints of the first men who walked these lands. A walk along the eaves and caves will allow you to observe cave paintings (originals and reproductions) as well as an exceptional view of Lake Argentino and the Andes Mountains. The circuit also passes through the main streets of the old town, showing you the typical first constructions of the pioneers as well as the current civic center buildings. The guide will be responsible for transporting you back in time to the history of the first settlers, showing you the flora, fauna and other interesting details that this small Tourist Village treasures. You will visit the Paseo Costero and Bahía Redonda, just meters from the coast of Lake Argentino, where you can observe the abundant local birdlife. As a last stop, at the Historical Interpretation Center you will observe, through a self-guided tour, the events of greatest historical relevance from the ice ages to the present.",
        src1: "cityWalichu1",
        src2: "cityWalichu2",
        src3: "cityWalichu3",
    },    

]

export function getTour(id) {
    return DatosTours.find((DatosTour) => DatosTour.id === id)
}



/* 
MOdificar whats

{
        id: '',
        imagen:'',
        icono: <DirectionsBoatIcon/>,
        tour: '',
        duracion: '',
        salidas: 'Consultar',
        link: '',
        incluye: '',
        spanishDesc: '',
        englishDesc: '',
        src1: "",
        src2: "",
        src3: "",
    },

        {
        id: 'cerroHuyliche',
        imagen: 'cerroHuyliche',
        icono: <LandscapeIcon/>,
        tour: 'Cerro Huyliche 4x4 y Zipline',
        duracion: ' 4 horas',
        salidas: ' am-pm A Confirmar',
        link: 'https://walink.co/b1a5e8',
        incluye: 'Translado - Guia - Equipamiento - Almuerzo/ Merienda/ Cena (Depende el horario)',
        spanishDesc: 'Desde El Calafate, en tan solo minutos, comenzaremos el ascenso al cordón Huyliche, el recorrido comienza transitando por las laderas del Cañadon del Arroyo Calafate, desde donde podremos avistar la fauna autóctona. Continuaremos recorriendo el sinuoso camino en ascenso rodeado de bloques erráticos, testigos del poder de la erosión de los glaciares hasta el Primer Balcón, escenario de la Era Cenozoica, datada en 65 millones de años de antigüedad. Luego de descender de los vehículos unos minutos, continuaremos hasta el balcón intermedio donde los guías colocarán los equipos.  Este es el punto de inicio del circuito de tirolesas donde comienza la máxima aventura descendiendo el cerro por las 4 líneas de un total de 2920 metros. Continuando el descenso arribaremos a la Estancia Huyliche donde serviremos el almuerzo, merienda o cena según el horario reservado. ** La excursión Cerro Huyliche 4x4 tiene 3 opciones para realizar la bajada: 4x4, Tirolesa o Trekking. Si viaja en familia o con amigos y eligen opciones distintas compartirán el ascenso al cerro en 4x4 y el tiempo en el casco de la Estancia Huyliche. **',
        englishDesc: "From El Calafate, in a few minutes, we will start the ascent to the Huyliche mountain range. The route starts by traveling along the slopes of the Cañadon del Arroyo Calafate, from where we can see the native fauna. We will continue traveling the winding path uphill surrounded by erratic blocks, witnesses of the power of glacial erosion until the First Balcony, scene of the Cenozoic Era, dated 65 million years ago. After getting off the vehicles for a few minutes, we will continue to the intermediate balcony where the guides will place the equipment. This is the starting point of the zip line circuit where the maximum adventure starts descending the hill along the 4 lines for a total of 2920 meters. Continuing the descent we will arrive at Estancia Huyliche where we will serve lunch, snack or dinner according to the reserved time. ** The Cerro Huyliche 4x4 excursion has 3 options for the descent: 4x4, Zipline or Trekking. If you travel with family or friends and choose different options, you will share the ascent to the hill in a 4x4 and the time in the center of Estancia Huyliche. **",
        src1: "cerroHuyliche1",
        src2: "cerroHuyliche2",
        src3: "cerroHuyliche3",
    }, 
    {
        id: 'balcones',
        imagen: 'balcones',
        icono: <PanoramaIcon/>,
        tour: 'Balcones del Calafate',
        duracion: ' 3:30 horas',
        salidas: ' 10:00 hs/ 14:30hs',
        link: 'https://walink.co/85ab59',
        incluye: 'Translado - Guia - Almuerzo/ Merienda (Depende el horario)',
        spanishDesc: 'La aventura comienza cuando te pasamos a buscar por tu hotel desde allí salimos en viaje a una nueva aventura !!! Emprendemos ruta hacia la estancia Huyliche donde después de atravesar la huella durante 2 hs alcanzaremos una altura de 950 msnm llegando a Balcones. Desde este punto panorámico privilegiado podrán apreciar la belleza del paisaje, la inmensidad del Lago Argentino, vista de la Ciudad de El Calafate, naciente del Glaciar Perito Moreno y los cerros Fitz Roy y Torre (insignias de El Chalten). También se podrán observar hermosos paisajes, podrán fotografiar cóndores, águilas y zorros entre otros animales autóctonos. Luego haremos una caminata de 20m entre formaciones rocosas únicas, bosques erráticos y la predominante estepa Patagonia. Mas tarde llegaremos al refugio donde disfrutaran de una cálida bienvenida y podran degustar del almuerzo con bebidas incluidas, o una merienda patagónica. ALMUERZO: BIFE A LA CRIOLLA (CEBOLLA Y MORRÓN) EN SANDWICH O AL PLATO. MERINEDA PATAGONICA: TE, CAFÉ O CHOCOLATADA CON BIZCOCHOS DULCES Y SALADOS.',
        englishDesc: "The adventure strats when we pick you up at your hotel, from there we set out on a trip to a new adventure!!! We start the route towards the Huyliche ranch where after crossing the trail for 2 hours we will reach a height of 950 meters above sea level arriving at Balcones. From this privileged panoramic point you will be able to appreciate the beauty of the landscape, the immensity of Lake Argentino, view of the City of El Calafate, source of the Perito Moreno Glacier and the Fitz Roy and Torre hills (flagships of El Chalten). You can also observe beautiful landscapes, you can photograph condors, eagles and foxes among other native animals. Then we will do a 20m walk between unique rock formations, erratic forests and the predominant Patagonian steppe. Later we will arrive at the refuge where you will enjoy a warm welcome and can enjoy lunch with drinks included, or a Patagonian snack. LUNCH: CREOLE-STYLE STEAK (ONION AND MORRON) IN A SANDWICH OR ON THE PLATE. PATAGONIAN MERINEDA: TEA, COFFEE OR CHOCOLATE WITH SWEET AND SALTY CAKES.",
        src1: "balcones1",
        src2: "balcones2",
        src3: "balcones3",
    }, 
     {
        id: 'kayakGlaciar',
        imagen: 'kayakGlaciar',
        icono: <KayakingIcon/>,
        tour: 'Kayak Glaciar Perito Moreno',
        duracion: ' 9 horas',
        salidas: ' 10:30hs',
        link: 'https://walink.co/0a4e71',
        incluye: ' Guia - Snacks - Equipamiento',
        spanishDesc: 'Actividad única en el mundo donde se realiza Kayaking entre Icebergs dentro del Parque Nacional Los Glaciares.La experiencia de realizar kayak frente al Glaciar Perito Moreno es única, permitiendo navegar entre témpanos y visitar la pared norte de este glaciar como nunca antes se hizo. Para comenzar la travesía los guías le proporcionarán el equipo necesario: un traje seco Gore-Tex, traje térmico, botas, guantes y chaleco salvavidas...  te encontrarás navegando con tu kayak frente al Glaciar Perito Moreno, una experiencia que nunca olvidarás.',
        englishDesc: "Unique activity in the world where Kayaking is carried out among Icebergs within the Los Glaciares National Park. The experience of kayaking in front of the Perito Moreno Glacier is unique, allowing you to navigate between icebergs and visit the northern wall of this glacier like never before. To start the journey, the guides will provide you with the necessary equipment: a Gore-Tex dry suit, thermal suit, boots, gloves and life jacket... you will find yourself sailing with your kayak in front of the Perito Moreno Glacier, an experience that you will never forget.",
        src1: "kayakGlaciar1",
        src2: "kayakGlaciar2",
        src3: "kayakGlaciar3",
    },  

 */